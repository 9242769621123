import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

const Table: React.FC<any> = ({ tableData }) => {
  const columns: any = useMemo<MRT_ColumnDef<any>[]>(() => {
    return tableData?.columns.map((x: any) => {
      return { ...x }
    })
  }, [tableData.columns]);

  const reSplitAlphaNumeric = /(\d+)/gm
  const customAlphanumeric = (rowA:any, rowB:any, columnId:any) => {
    const a = rowA.getValue(columnId).toString().toLowerCase()
    const b = rowB.getValue(columnId).toString().toLowerCase()
    return compareAlphanumeric(a, b)
  }

  function compareAlphanumeric(aStr: string, bStr: string) {
    const aParts = aStr.split(reSplitAlphaNumeric).filter(Boolean)
    const bParts = bStr.split(reSplitAlphaNumeric).filter(Boolean)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const a = aParts[i] || ""
      const b = bParts[i] || ""

      const aNum = parseInt(a, 10)
      const bNum = parseInt(b, 10)

      // If both parts are numbers, compare numerically
      if (!isNaN(aNum) && !isNaN(bNum)) {
        if (aNum !== bNum) return aNum - bNum
      } 
      // Compare as strings otherwise
      else {
        if (a !== b) return a > b ? 1 : -1
      }
    }

    return 0
  }

  const table = useMaterialReactTable<any>({
    columns,
    data: tableData.data,
    ...tableData.options,
    sortingFns: { customAlphanumeric }
  });
  return (
    <div className={tableData.styles.layout}>
      {tableData.heading && <p className={tableData.styles.heading}>{tableData.heading}</p>}
      <MaterialReactTable table={table} />
    </div>
  )
};

export default Table;

