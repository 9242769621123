import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import { Role } from '../../shared/enums';
import useFormConfig from '../../config/useFormConfig';
import AppForm from '../../shared/ui-components/appForm/AppForm';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import Help from '../../shared/ui-components/Help';
import Modal from '../../shared/ui-components/Modal'
import Dropdown from '../../shared/ui-components/DropDown';

function Home() {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<Role>(Role.STUDENT);
  const { loginFormConfig: formConfig } = useFormConfig();
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState<string>('');
  const dispatch = useDispatch();
  const logo = require('../../../assets/images/trex_24_logo_login.png');
  const availableRoles = ['STUDENT', 'SCHOOL']

  const isLogged = () => {
    const data = localStorage.getItem('data');
    if (data) {
      const { rollNo } = JSON.parse(data);
      if (rollNo) {
        return true;
      }
      return false;
    }
    return false;
  }

  const handleSubmit = async (formData: Record<string, string>) => {
    try {
      formData.role = selectedRole;
      const res = await login(formData).unwrap();
      dispatch(setCredentials(res));
      if (res.user.role === Role.STUDENT) {
        localStorage.setItem('data', JSON.stringify({ rollNo: formData.rollNo, name: res.user.name }));
      }
      navigate(`${res.user.role.toLowerCase()}/dashboard`);
    } catch (err) {
      if (err.status === "FETCH_ERROR") {
        setErrMsg("Network Error");
      } else {
        setErrMsg(err.message);
      }
    }
  };

  const handleHelpClick = () => {
    setIsHelpModalOpen(true);
  }

  const handleRoleChange = (e: any) => {
    setSelectedRole(e.target.value as Role);
  };

  useEffect(() => {
    if (isLogged()) {
      navigate('student/dashboard');
    }
  }, [navigate]);

  return (
    <section className="login-section">
      <div className='logo-container'>
        <img
          src={logo}
          className="login-logo"
          alt="Sample"
        />
      </div>
      <div className="form-container">
        <p className="form-head-text">TReX Result Portal</p>
        {errMsg && <p className="danger-text">{errMsg}</p>}
        <div className="role-dropdown-container">
          <Dropdown
            label="Select Role"
            options={availableRoles}
            value={selectedRole}
            onChange={handleRoleChange}
          />
        </div>
        {selectedRole && (
          <AppForm
            formConfig={formConfig[selectedRole]}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            onHelpClick={handleHelpClick}
          />
        )}
      </div>
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
        <Help role={selectedRole} containerStyle='!w-full'/>
      </Modal>
    </section>
  );
}

export default Home;
