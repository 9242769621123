import React, { useState, useEffect } from 'react';
import { useGetClassWiseResultQuery } from '../../features/school/schoolApiSlice';
import { GetDefaultClassResultOptions } from '../../features/school/tableOptions';
import Table from '../../shared/ui-components/TableComp';
import Dropdown from '../../shared/ui-components/DropDown';
import { selectResultModalState, toggleResultModalState, setResultModalCredentials, selectResultModalCredentials, ROLLNO } from '../../features/school/schoolSlice';
import { useSelector, useDispatch } from 'react-redux';
import Result from '../../shared/ui-components/Result';
import GenericModal from '../../shared/ui-components/Modal';
import { exam, BASE_URL } from '../../utils/constants';
import { selectCurrentUser } from '../../features/auth/authSlice';
import './styles.css';

const SchoolDashboard = () => {
  const availableClasses = [...Array(6)].map((_, i) => `Class ${i + 5}`);
  const [selectedClass, setSelectedClass] = useState(availableClasses.slice(0)[0]);
  const { data, isFetching, error } = useGetClassWiseResultQuery({ exam, class: selectedClass.slice(6,) });
  const options = GetDefaultClassResultOptions();
  const user = useSelector(selectCurrentUser);
  const modalState = useSelector(selectResultModalState);
  const modalCredentials = useSelector(selectResultModalCredentials);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleResultModalState());
    dispatch(setResultModalCredentials({}));
  };
  const handleClassChange = (e: any) => {
    setSelectedClass(e.target.value);
  };

  useEffect(() => {
    if (data && data.table && data.table.columns) {
      const modifiedColumns = data.table.columns.map((col: any) => {
        if (col.accessorKey === "Rank") {
          return {
            ...col,
            Header: React.createElement('p', { style: { whiteSpace: "pre-line", textAlign: "left" } }, col.accessorKey),
            sortingFn: 'customAlphanumeric',
          };
        }
        return {
          ...col,
          Header: React.createElement('p', { style: { whiteSpace: "pre-line", textAlign: "left" } }, col.accessorKey)
        };
      });
      options.columns = modifiedColumns;
    }
  }, [data, options]);

  return (
    <>
      <div className='class-select-dropdown'>
        <Dropdown
          label="Select Class"
          options={availableClasses}
          value={selectedClass}
          onChange={handleClassChange}
        />
      </div>
      <div className='class-result'>
        {isFetching && <p>Loading...</p>}
        {!isFetching && error && <p>{(error as any).message || "No data available for the selected class"}</p>}
        {!isFetching && !data?.table?.data?.length && <p>No data available for the selected class</p>}
        {!isFetching && !error && data?.table?.data?.length > 0 && <div className='school-table'>
          <p className='top-text'>
            <a href={`${BASE_URL}/school/getSchoolReport/${user?.id}/${selectedClass.slice(6,)}`}><button className='submit-btn mb-3 text'>
            Download School Report
            </button>
            </a>
          </p>
          <Table tableData={{ ...data.table, options }} />
          </div>}
      </div>
      <GenericModal isOpen={modalState} onClose={handleClose}>
        <Result exam={modalCredentials.exam} rollNo={modalCredentials[ROLLNO]} />
      </GenericModal>
    </>
  );
};

export default SchoolDashboard;
