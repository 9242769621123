import { useState } from 'react';
import { useResultSummaryQuery, useSubjectsQuery, useTopicsQuery, useDifficultyLevelsQuery } from '../../features/result/resultApiSlice';
import '../styles/styles.css';
import Table from './TableComp';
import Visual from './Visual';
import { getDefaultResultOptions } from '../../features/result/tableOptions';
import Dropdown from './DropDown';
import { fontSize } from '../../utils/constants';

interface ResultProps {
  rollNo: string;
  exam: string;
  showTabs?: boolean;
}

const Result = ({ rollNo, exam, showTabs = true }: ResultProps) => {
  const options = getDefaultResultOptions();
  if (!showTabs) {
    options.muiTableHeadCellProps = { sx: { fontSize: fontSize.pdfTable } }
    options.muiTableBodyCellProps = { sx: { fontSize: fontSize.pdfTable } }
  }
  const [activeTab, setActiveTab] = useState(0);
  const { data: summary, error: summaryError, isLoading: summaryLoading } = useResultSummaryQuery({ exam, rollNo, });
  const { data: subjects, error: subjectsError, isLoading: subjectsLoading } = useSubjectsQuery({ exam, rollNo, });
  const { data: topicsData, error: topicsError, isLoading: topicsLoading } = useTopicsQuery({ exam, rollNo, });
  const { data: difficultyLevels, error: difficultyLevelsError, isLoading: difficultyLevelsLoading } = useDifficultyLevelsQuery({ exam, rollNo, });
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  const sectionWiseResult = topicsData?.length > 0 && !topicsLoading && !difficultyLevelsLoading && (
    topicsData?.map((topicTable: any, index: number) => (
      <div key={index} className='topics-data'>
        <p className="heading">{topicTable.subjectName}</p>
        <Table tableData={{ ...topicTable.table, options }} />
        {difficultyLevels?.length > 0 && (
          <>
            <Table tableData={{ ...difficultyLevels[index].table, options }} />
            <Visual chart={difficultyLevels[index].chart} />
          </>)}
      </div>
    )));

  return (
    <div className="result-section" id="result">
      {summaryError && subjectsError && topicsError && difficultyLevelsError && <p className="error">Data Not Available!</p>}
      {!summaryError && !summaryLoading &&
        <div className="result-summary">
          {Object.entries(summary).map(([key, value]) => (
            <div key={key} className="summary-item">
              <p className="text-bold">{key}:</p>
              <p className="text">{value as React.ReactNode}</p>
            </div>
          ))}
        </div>
      }
      {!subjectsError && !subjectsLoading && (
        <div className='result-sub-section'>
          {subjects.table?.data?.length > 0 && <Table tableData={{ ...subjects.table, options }} />}
          {subjects.chart?.data?.length > 0 && <Visual chart=
          {subjects.chart} />}
        </div>
      )}
      {!topicsLoading && !difficultyLevelsLoading && (
        <div className='result-sub-section'>
          <p className="heading">Section Wise Results</p>
          {showTabs &&
            <>
              <ul className="tabs-container">
                {topicsData?.length > 0 && !topicsError && topicsData?.map((topic: any, index: number) => (
                  <li className="me-3" key={index}>
                    <button
                      className={`tabs-item text ${activeTab === index && 'active'}`}
                      onClick={() => handleTabClick(index)}>
                      {topic.subjectName}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="dropdown-container">
                <Dropdown
                  label="Subject"
                  options={topicsData?.map((topic: any) => topic.subjectName)}
                  value={activeTab}
                  onChange={(event: any) => handleTabClick(event.target.value)}
                />
              </div>
              {!topicsError && topicsData?.length > 0 && (
                <>
                  <div className="legend-container">
                    <p className='mr-4'>🟢🟢🟢 Good</p>
                    <p className='mr-4'>🟠🟠 Average</p>
                    <p className='mr-4'>🔴 Needs Improvement</p>
                  </div>
                  <Table tableData={{ ...topicsData[activeTab].table, options }} />
                </>
              )}
              {!difficultyLevelsError && difficultyLevels?.length > 0 && (
                <>
                  <Table tableData={{ ...difficultyLevels[activeTab].table, options }} />
                  <Visual chart={difficultyLevels[activeTab].chart} />
                </>
              )}
            </>}
          {!showTabs && sectionWiseResult}
        </div>
      )}
    </div>
  );
};

export default Result;