import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../features/auth/authSlice';
import { User } from '../features/auth/authSlice';
import { FieldType, Role } from '../shared/enums';

export interface FormField {
    label?: string;
    name: string;
    type: FieldType;
    placeholder: string;
    required?: boolean;
    value?: string | null;
    disabled?: boolean;
    id?: string;
    className?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    rows?: number;
    checked?: boolean;
    showLabel?: boolean;
    order?: number;
    validator?: (value: string) => string;
}

export interface formButton {
    label: string;
    className?: string;
}

export interface FormConfig {
    fields: FormField[];
    submitBtn?: formButton;
    help?: string;
}

export interface RoleFormConfig {
    [fieldName: string]: FormField;
}

const useFormConfig = () => {
    const user: User = useSelector(selectCurrentUser);
    const rollNoValidator =(value: string) => (/^\d{4}$/.test(value) ? '' : 'Roll No must be an 4-digit number');

    const generateCommonFields = (): FormField[] => [
        { label: 'Email', name: 'email', type: FieldType.EMAIL, placeholder: 'Enter your email', required: true, order: 3 },
        {
            label: 'Phone Number', name: 'phone', type: FieldType.TEL, placeholder: 'Enter your phone number', required: true, order: 4,
            validator: (value: string) => (/^\d{10}$/.test(value) ? '' : 'Phone number must be a 10-digit number'),
        },
        { label: 'Message', name: 'note', type: FieldType.TEXTAREA, placeholder: 'Provide a note (Optional)', rows: 4, required: false, order: 5 },
    ];

    const studentHelpFormFields: FormField[] = [
        ...generateCommonFields(),
        { label: 'Name', name: 'name', type: FieldType.TEXT, placeholder: 'Enter your name', value: user?.name || '', disabled: user?.name ? true : false, required: true, order: 1 },
        { label: 'Roll Number', name: 'rollNo', type: FieldType.TEXT, placeholder: 'Enter your roll number', value: user?.id || '', disabled: user?.id ? true : false, required: true, order: 2, validator: rollNoValidator },
    ];

    const schoolHelpFormFields: FormField[] = [
        ...generateCommonFields(),
    ];


    const studentHelpFormConfig: FormConfig = {
        fields: studentHelpFormFields,
        submitBtn: { label: 'Submit', className: 'submit-btn' }, //modify classes in appForm/styles.css
    }

    const schoolHelpFormConfig: FormConfig = {
        fields: schoolHelpFormFields,
        submitBtn: { label: 'Submit', className: 'submit-btn' }, //modify classes in appForm/styles.css
    }

    const loginFormFields: any = {
        rollNo: {
            name: 'rollNo', label: 'Roll No', type: FieldType.TEXT, required: true, showLabel: true, order: 1,
            validator: rollNoValidator,
        },
        dob: { name: 'dob', label: 'Date of Birth', type: FieldType.DATE, placeholder: 'Select Date of Birth', required: true, showLabel: true, order: 2 },
        username: { name: 'username', label: 'Username', type: FieldType.TEXT, placeholder: 'Enter Username', required: true, showLabel: true, order: 1 },
        password: { name: 'password', label: 'Password', type: FieldType.PASSWORD, placeholder: 'Enter Password', required: true, showLabel: true, order: 2 },
        rememberMe: { name: 'rememberMe', label: 'Remember Me', type: FieldType.CHECKBOX, placeholder: 'Remember Me', required: false, showLabel: false, order: 3 },
    };

    const loginBtn: formButton = { label: 'Login', className: 'submit-btn' };
    const loginFormConfig: Record<Role, FormConfig> = {
        STUDENT: { fields: [loginFormFields.rollNo, loginFormFields.dob], submitBtn: loginBtn, help: "Need help?" },
        SCHOOL: { fields: [loginFormFields.username, loginFormFields.password], submitBtn: loginBtn, help: "Need help?" },
        ADMIN: { fields: [loginFormFields.username, loginFormFields.password], submitBtn: loginBtn, },
    }
    return { studentHelpFormConfig, loginFormConfig, schoolHelpFormConfig };
}

export default useFormConfig;
