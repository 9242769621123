import { apiSlice } from "../../api/apiSlice";
import { transformClassWiseResultResponse, transformAvailableClassesResponse } from "./services";
import { handleResponse } from "../../api/apiSlice";

export const schoolApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClassWiseResult: builder.query({
            query: (credentials) => ({
                url: `/school/getClassWiseResult/${credentials.exam}/${credentials.class}`,
                method: 'GET',
            }),
            transformResponse: (rawData: any) => {
                return handleResponse(rawData, transformClassWiseResultResponse);
            }
        }),
        getAvailableClassesForSchool: builder.query({
            query: () => ({
                url: `/school/getAvailableClassesForSchool`,
                method: 'GET',
            }),
            transformResponse: (rawData: any) => {
                return handleResponse(rawData, transformAvailableClassesResponse);
            }
        }) 
    }),
});

export const {
    useGetClassWiseResultQuery,
    useGetAvailableClassesForSchoolQuery,
} = schoolApiSlice



