import { type MRT_RowData, type MRT_TableOptions } from 'material-react-table';
import { toggleResultModalState, setResultModalCredentials, ROLLNO } from './schoolSlice';
import { useDispatch } from 'react-redux';
import { getAttributeScreenOptimized } from '../../utils/screenOptimize';

const fontSize = getAttributeScreenOptimized(['0.7rem', '0.75rem', '0.85rem']);
const padding = getAttributeScreenOptimized(["4px", "8px", "12px"]);

export const GetDefaultClassResultOptions = <TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> => {
  const dispatch = useDispatch();
  return (
    {
      muiTableBodyCellProps: ({ row, column }) => {
        if (column.id === "View Result") {
          return {
            onClick: () => {
              dispatch(toggleResultModalState());
              dispatch(setResultModalCredentials({ "Roll No": row.original[ROLLNO], exam: "TrexRound2" }));
            },
            sx: {
              cursor: "pointer",
              fontSize: "1.5rem",
              '&:hover': {
                fontSize: "2rem",
              },
            },
          };
        }
        return {
          sx: {
            fontSize,
            padding,
          }
        };
      },
      muiTableHeadCellProps: {
        sx: {
          fontSize,
          padding,
        }
      },
      enableTopToolbar: false,
    })
}


