export const transformClassWiseResultResponse = (data: any[]): any => {
  const tableData = data.map((item) => {
    return {
      'TReX Roll No': item.rollNo,
      Name: item.name,
      DOB: item.dob,
      Rank: item.rank,
      // Section: item.section,
      'Maximum Marks': item.maximumMarks,
      'Total Marks': item.totalMarks,
      'MAT Marks': item.matMarks,
      'Maths Marks': item.mathMarks,
      'Science Marks ': item.sciMarks,
      // 'View Result': "\u{1F517}",
    }
  });

  const columns = tableData.length > 0 ? Object.keys(tableData[0]).map((key) => ({ accessorKey: key, header: key, size: 10, })) : [];
  const tableStyles = { layout: "table-layout", heading: "heading" };
  return {
    table: {
      columns,
      data: tableData,
      heading: 'Class Wise Result',
      styles: tableStyles,
    },
  };
}

export const transformAvailableClassesResponse = (data: any[]): any => {
  return data.map((i) => `Class ${i}`);
}